/* eslint-disable @next/next/no-html-link-for-pages */
import {useEffect} from 'react';
import {datadogRum} from '@datadog/browser-rum';
import Head from 'next/head';
import Image from 'next/image';
import {useRouter} from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import logoDark from '@/assets/images/brand/logo-dark.png';

type Props = {
  error?: unknown;
};

const NotFound: React.FC<Props> = ({error}) => {
  const {t} = useTranslation('common');
  const {pathname, query} = useRouter();

  useEffect(() => {
    if (error) datadogRum.addError(error, {url: {pathname, query}});
    datadogRum.addAction('error-component-rendered', {
      component: 'NotFound',
      error,
      url: {
        pathname,
        query,
      },
    });
  }, [error, pathname, query]);

  return (
    <>
      <Head>
        <title>{t('pageTitle.404')}</title>
      </Head>
      <a href="/" className="block">
        <Image alt="ezCater Logo" src={logoDark} priority />
      </a>
      <h1>The requested page was eaten.</h1>
      <div>
        <p>
          At least, we can&#39;t find it in the fridge. It may have been moved. Sorry things
          aren&#39;t so ez right now. If you need immediate assistance, please call 1-800-488-1803.
        </p>
        <a href="/">Return to ezCater</a>
      </div>
    </>
  );
};

export default NotFound;
