
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import NotFound from '@/components/NotFound';
type Props = {
    error?: unknown;
};
const NotFoundPage: React.FC<Props> = ({ error }) => (<div className="m-auto mt-8 flex max-w-lg flex-col items-center gap-3 rounded border border-solid border-gray-300 p-8 text-center">
    <NotFound error={error}/>
  </div>);
export default NotFoundPage;

    async function __Next_Translate__getStaticProps__195fd59aec9__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__195fd59aec9__ as getStaticProps }
  